.browser {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
}

.browser :global(.controls) {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
}

.browser :global(.controls div.control) {
    padding: 2px 5px;
    margin: 2px 5px;
}